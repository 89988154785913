<template>
  <div>
    <h1 class="font-barlow font-bold text-xl text-grey-dark-2 mb-3">
      Team Management
    </h1>
    <div v-if="!!teams.length" class="flex w-full flex-wrap container">
      <TeamCard
        v-for="(team, index) in teams"
        :key="team.id"
        :team="team"
        :index="index"
        :onClick="viewDetails"
      />
    </div>
    <div v-else>
      <p class="ml-3 text-grey-dark-1 font-medium">
        No teams created yet.
      </p>
      <p class="ml-3 text-grey-dark-1 font-medium">
        You have not been assigned to manage a team in the Advance Digital
        Platform.
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TeamCard from "../components/TeamCard.vue";
export default {
  components: { TeamCard },
  name: "TeamManagement",
  computed: {
    ...mapState({
      teams: (state) => state.teams.teams,
    }),
  },
  methods: {
    ...mapActions({
      getTeams: "teams/getTeams",
    }),
    viewDetails(id) {
      this.$router.push(`/teams/${id}`);
    },
  },
  async mounted() {
    await this.getTeams();
  },
};
</script>
