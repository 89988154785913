<template>
  <div class="w-1/3 px-2 mb-4">
    <div
      class="flex items-center justify-center py-7 bg-center bg-no-repeat bg-cover rounded-t"
      :style="`background-image: url(${returnBg});`"
    >
      <p class="text-white font-barlow font-bold text-l-2 text-center">
        {{ team.name }}
      </p>
    </div>
    <div class="bg-white rounded-b h-150 pb-2 flex flex-col">
      <div class="flex flex-wrap p-2 inline">
        <p
          v-for="(user, index) in team.users.slice(0, userCount)"
          :key="user.id"
          class="mr-0.4 font-bold text-sm-2 text-grey-dark-2"
        >
          {{
            user.firstName +
              " " +
              user.lastName +
              (index != userCount - 1 ? "," : "")
          }}
        </p>
        <p class="text-sm-2 text-grey-dark-2">
          {{ otherMembers }}
        </p>
      </div>
      <div class="flex justify-center mt-auto">
        <Button
          text="View Details"
          type="secondary"
          :iconRight="arrow"
          size="medium"
          :class="userCount >= 2 ? 'mt-2' : 'mt-4'"
          :onClick="extendOnClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button/Button.vue";
import bg1 from "../../../assets/img/teams/team-1.svg";
import bg2 from "../../../assets/img/teams/team-2.svg";
import arrow from "@/assets/img/icons/arrow-right.svg";

export default {
  components: { Button },
  name: "TeamCard",
  data: () => ({
    arrow,
    userCount: null,
    0: bg1,
    1: bg2,
  }),
  props: {
    team: {
      type: Object,
    },
    index: {
      type: Number,
    },
    onClick: { type: Function },
  },
  mounted() {
    this.userCount = this.team.users.length < 4 ? this.team.users.length : 4;
  },
  computed: {
    returnBg() {
      return this.$data[this.index % 2];
    },
    otherMembers() {
      if (this.userCount == this.team.users.length) {
        return "";
      } else
        return `and ${this.team.totalUsersCount - this.userCount} other member${
          this.team.totalUsersCount - this.userCount > 1 ? "s" : ""
        }`;
    },
    teamMembers() {
      var text = "";
      for (let i = 0; i < this.userCount; i++) {
        text +=
          i < this.userCount - 1
            ? this.team.users[i].firstName +
              " " +
              this.team.users[i].lastName +
              ", "
            : this.team.users[i].firstName + " " + this.team.users[i].lastName;
      }
      return text;
    },
  },
  methods: {
    extendOnClick() {
      this.onClick(this.team.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  flex: 0 0 33%;
}
</style>
